<template>
  <v-app>
    <app-header class="header"></app-header>
    <v-content class="page-details">
      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      mode="out-in">
        <router-view class="route-details"></router-view>
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import Header from './components/Header'

export default {
  name: 'App',
  metaInfo () {
    return {
      title: 'Marvin Kweyu',
      titleTemplate: '%s | - Software developer and linux enthusiast',
      meta: [
        { name: 'description', content: 'Software developer and linux enthusiast' },
        { name: 'keywords', content: 'marvin, django, kweyu, bash, terminal, python, scripting, opensource, software,algorithms, zsh, linux, engineering,green,codes, green codes,ColorDetect, color, AssessMe,traffic-analysis, alkaline-water, hamasisha, analytics, detect,mysql' }
      ]
    }
  },

  components: {

    appHeader: Header
  },

  data: () => ({
    //
  })
}
</script>

<style>
  *{
    font-size: 18px;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    /*font-size: 16px;*/
  }
  p, small{
    color: #2c3e50 !important;
  }
  >>> body {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 16px;
    color: #2c3e50 !important;
  }

  @media (min-width: 640px) { body {font-size:1rem;} }
  @media (min-width:960px) { body {font-size:1.2rem;} }
  @media (min-width:1100px) { body {font-size:1.5rem;} }
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 88px;
    z-index: 10;
  }
  .page-details{
    margin-top: 75px;
    background-color: white;
  /*    margin-top: 100px;*/
  }
  .route-details{
    /*background-color: rgb(247, 245, 239);*/
  }
</style>
