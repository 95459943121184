<template>
  <div class="home">
    <app-about></app-about>
  </div>
</template>

<script>
// @ is an alias to /src
import About from '../components/Home/About'

export default {
  name: 'Home',
  metaInfo: {
    meta: [
      { name: 'description', content: 'All about Marvin' }, { name: 'keywords', content: 'django,vue,bash' }
    ]
  },
  components: {
    appAbout: About
  },
  created () {
    this.$store.dispatch('fetchPosts')
  }
}
</script>

<style>
.home {
  padding-top: 0.5em;
  padding-right: 10%;
  padding-bottom: 10%;
  padding-left: 10%;
  /* background-color: red; */
}
</style>
