<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark static-top">
      <div class="container-fluid">
        <router-link to="/">
          <a class="navbar-brand mr-3">Marvin</a>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      collapsed: true
    }
  }
}

</script>
