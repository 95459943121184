const { createApolloFetch } = require('apollo-fetch')

const fetch = createApolloFetch({
  uri: 'https://api.hashnode.com'
})

// const token = 'd9c40121-41db-41bd-9ccc-e394b43b175f'
// fetch.use(({ request, options }, next) => {
//   options.headers = {
//     Authorization: token
//   }
//   next()
// })

// const state = () => ({
//   posts: [],
//   postCuid: [],
//   singlePost: {},
//   postTags: [],
//   filteredPosts: []
// })

const state = {
  posts: [],
  postCuid: [],
  singlePost: {},
  postTags: [],
  filteredPosts: []
}
const mutations = {
  setPosts (state, data) {
    state.posts = data
  },
  setSinglePost (state, data) {
    state.singlePost = data
  },
  setTags (state, data) {
    state.postTags = data
  },
  setCuid (state, data) {
    state.postCuid = data
  },
  setFilteredPosts (state, data) {
    state.filteredPosts = data
  }
}

const actions = {
  fetchPosts ({ commit, dispatch }) {
    fetch({
      query:
        '{ user(username: "marvin"){publication{posts {title,cuid,slug,popularity,dateAdded,coverImage,brief,contentMarkdown}}} }'
    }).then(res => {
      commit('setPosts', res.data.user.publication.posts)
      // * use when tags are present from the endoint. Limit errors for now
      // dispatch('getAllTagsPosted')
    })
      .catch(error => {
        console.log(error)
      })
  },
  fetchSinglePostDetail ({ commit, state }, cuid) {
    // get single post detail with this cuid from the list of post objects
    const allPosts = state.posts

    // console.log('Searching CUID', cuid)
    const postDetail = allPosts.find(post => {
      return post.cuid === cuid
    })
    // console.log('single post detail', postDetail)
    commit('setSinglePost', postDetail)
  },
  fetchUnrelatedSinglePostDetail ({ commit, state }, postSlug) {
    // Todo figure out how to get the title from the slug
    fetch({ query: '{post(slug: postSlug,hostname: "ColorDetection -  Python ColorDetect package") {slug, dateAdded,title,brief,coverImage, tags{name},contentMarkdown}}' }).then(res => {
      console.log(res.data)
    })
      .catch(error => {
        console.log(error)
      })
  },
  getAllTagsPosted ({ commit, state }) {
    const allPosts = state.posts

    const allCuid = allPosts.map(value => value.cuid)
    const allTags = []
    allCuid.forEach(cuid => {
      fetch({
        query:
          `{post(cuid: "${cuid}") {tags {name} }}`
      })
        .then(res => {
          const arrayOfTagObjects = res.data.post.tags
          // console.log(arrayOfTagObjects)
          const tagsOfPost = arrayOfTagObjects.map(value => value.name)
          allTags.push(...tagsOfPost)
        })
        .catch(error => {
          console.log(error)
        })
    }
    )
    commit('setTags', allTags)
    commit('setCuid', allCuid)
  },
  filterByTag ({ commit, state }, tagNeeded) {
    const allCuid = state.postCuid
    const postsNeeded = []
    allCuid.forEach(cuid => {
      fetch({
        query:
          `{post(cuid: "${cuid}") {title, brief,popularity,dateAdded,tags {name} }}`
      })
        .then(res => {
          const arrayOfTagObjects = res.data.post.tags
          const tagsOfPost = arrayOfTagObjects.map(value => value.name)
          if (tagsOfPost.includes(tagNeeded)) {
            // append cuid to post object
            res.data.post.cuid = cuid
            postsNeeded.push(res.data.post)
          }
        })
        .catch(error =>
          console.log(error))
    })
    commit('setFilteredPosts', postsNeeded)
  }
}

const getters = {
  getPosts: state => {
    return state.posts
  },
  getSinglePost (state) {
    return state.singlePost
  },
  getTags (state) {
    const distinctTags = [...new Set(state.postTags)]
    // return state.postTags
    return distinctTags
  },
  getFilteredPosts (state) {
    return state.filteredPosts
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
