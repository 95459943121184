import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import VueMeta from 'vue-meta'

Vue.use(VueSimpleMarkdown)
Vue.use(VueMeta)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.filter('publishingDay', (value) => {
  return value.split('T')[0]
})

const { createApolloFetch } = require('apollo-fetch')

const fetch = createApolloFetch({
  uri: 'https://api.hashnode.com'
})

const token = 'd9c40121-41db-41bd-9ccc-e394b43b175f'
fetch.use(({ request, options }, next) => {
  options.headers = {
    Authorization: token
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
